const LayersIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M11.5038 3.13176C11.8112 2.95608 12.1886 2.95608 12.4961 3.13176L19.4961 7.13176C19.8076 7.3098 19.9999 7.64114 19.9999 8C19.9999 8.35886 19.8076 8.6902 19.4961 8.86824L12.4961 12.8682C12.1886 13.0439 11.8112 13.0439 11.5038 12.8682L4.50379 8.86824C4.19221 8.6902 3.99993 8.35886 3.99993 8C3.99993 7.64114 4.19221 7.3098 4.50379 7.13176L11.5038 3.13176ZM7.01549 8L11.9999 10.8482L16.9844 8L11.9999 5.15175L7.01549 8ZM4.13168 11.5039C4.40569 11.0243 5.01655 10.8577 5.49607 11.1318L11.9999 14.8482L18.5038 11.1318C18.9833 10.8577 19.5942 11.0243 19.8682 11.5039C20.1422 11.9834 19.9756 12.5942 19.4961 12.8682L12.4961 16.8682C12.1886 17.0439 11.8112 17.0439 11.5038 16.8682L4.50379 12.8682C4.02427 12.5942 3.85767 11.9834 4.13168 11.5039ZM4.13168 15.5039C4.40569 15.0243 5.01655 14.8577 5.49607 15.1318L11.9999 18.8482L18.5038 15.1318C18.9833 14.8577 19.5942 15.0243 19.8682 15.5039C20.1422 15.9834 19.9756 16.5942 19.4961 16.8682L12.4961 20.8682C12.1886 21.0439 11.8112 21.0439 11.5038 20.8682L4.50379 16.8682C4.02427 16.5942 3.85767 15.9834 4.13168 15.5039Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default LayersIcon;