import React from 'react';

const RepeatIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M15.2929 3.29289C15.6834 2.90237 16.3166 2.90237 16.7071 3.29289L19.7071 6.29289C20.0976 6.68342 20.0976 7.31658 19.7071 7.70711L16.7071 10.7071C16.3166 11.0976 15.6834 11.0976 15.2929 10.7071C14.9024 10.3166 14.9024 9.68342 15.2929 9.29289L16.5858 8H6C5.44772 8 5 8.44772 5 9V11C5 11.5523 4.55228 12 4 12C3.44772 12 3 11.5523 3 11V9C3 7.34315 4.34315 6 6 6H16.5858L15.2929 4.70711C14.9024 4.31658 14.9024 3.68342 15.2929 3.29289ZM20 12C20.5523 12 21 12.4477 21 13V15C21 16.6569 19.6569 18 18 18H8.41421L9.70711 19.2929C10.0976 19.6834 10.0976 20.3166 9.70711 20.7071C9.31658 21.0976 8.68342 21.0976 8.29289 20.7071L5.29289 17.7071C4.90237 17.3166 4.90237 16.6834 5.29289 16.2929L8.29289 13.2929C8.68342 12.9024 9.31658 12.9024 9.70711 13.2929C10.0976 13.6834 10.0976 14.3166 9.70711 14.7071L8.41421 16H18C18.5523 16 19 15.5523 19 15V13C19 12.4477 19.4477 12 20 12Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default RepeatIcon;