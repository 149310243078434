const Person2Icon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M15.5 6C14.6716 6 14 6.67157 14 7.5C14 8.32843 14.6716 9 15.5 9C16.3284 9 17 8.32843 17 7.5C17 6.67157 16.3284 6 15.5 6ZM12 7.5C12 5.567 13.567 4 15.5 4C17.433 4 19 5.567 19 7.5C19 9.433 17.433 11 15.5 11C13.567 11 12 9.433 12 7.5ZM8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7ZM5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8ZM11.1115 17.5H19.8885C19.4338 15.496 17.6416 14 15.5 14C13.3584 14 11.5662 15.496 11.1115 17.5ZM9 18.5C9 14.9101 11.9101 12 15.5 12C19.0899 12 22 14.9101 22 18.5C22 19.0523 21.5523 19.5 21 19.5H10C9.44772 19.5 9 19.0523 9 18.5Z" 
        fill={props.svgColor}
      />
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M9.73403 12.2547C9.18424 12.0889 8.60185 12 8 12C4.68629 12 2 14.6863 2 18C2 18.5523 2.44772 19 3 19H7V18.5C7 17.9882 7.04524 17.4869 7.13193 17H4.12602C4.57006 15.2748 6.13616 14 8 14C8.0947 14 8.18856 14.0033 8.28149 14.0097C8.68586 13.361 9.17523 12.7708 9.73403 12.2547Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default Person2Icon;