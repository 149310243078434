import React from 'react';

const RibbonIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M9.76872 3.19414C10.2661 2.44811 11.1034 2 12 2C12.8966 2 13.7339 2.44811 14.2313 3.19414L14.7652 3.99507C14.996 4.34117 15.4168 4.50829 15.8221 4.41476L16.47 4.26526C17.3846 4.0542 18.3434 4.32918 19.0071 4.99289C19.6708 5.65661 19.9458 6.61541 19.7347 7.53001L19.5852 8.17786C19.4917 8.58316 19.6588 9.00404 20.0049 9.23477L20.8059 9.76872C21.5519 10.2661 22 11.1034 22 12C22 12.8966 21.5519 13.7339 20.8059 14.2313L20.0049 14.7652C19.6588 14.996 19.4917 15.4168 19.5852 15.8221L19.7347 16.47C19.9458 17.3846 19.6708 18.3434 19.0071 19.0071C18.3434 19.6708 17.3846 19.9458 16.47 19.7347L15.8221 19.5852C15.4168 19.4917 14.996 19.6588 14.7652 20.0049L14.2313 20.8059C13.7339 21.5519 12.8966 22 12 22C11.1034 22 10.2661 21.5519 9.76872 20.8059L9.23477 20.0049C9.00404 19.6588 8.58316 19.4917 8.17786 19.5852L7.53001 19.7347C6.61541 19.9458 5.6566 19.6708 4.99289 19.0071C4.32918 18.3434 4.0542 17.3846 4.26526 16.47L4.41476 15.8221C4.50829 15.4168 4.34117 14.996 3.99507 14.7652L3.19414 14.2313C2.44811 13.7339 2 12.8966 2 12C2 11.1034 2.44811 10.2661 3.19414 9.76872L3.99507 9.23477C4.34117 9.00404 4.50829 8.58316 4.41476 8.17786L4.26526 7.53001C4.0542 6.61541 4.32918 5.6566 4.99289 4.99289C5.6566 4.32918 6.61541 4.0542 7.53001 4.26526L8.17786 4.41476C8.58316 4.50829 9.00404 4.34117 9.23477 3.99507L9.76872 3.19414Z"
        fill={props.svgColor}
      />
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M16.7071 8.79289C17.0976 9.18342 17.0976 9.81658 16.7071 10.2071L10.7071 16.2071C10.3166 16.5976 9.68342 16.5976 9.29289 16.2071L7.29289 14.2071C6.90237 13.8166 6.90237 13.1834 7.29289 12.7929C7.68342 12.4024 8.31658 12.4024 8.70711 12.7929L10 14.0858L15.2929 8.79289C15.6834 8.40237 16.3166 8.40237 16.7071 8.79289Z"
        fill="#FFFFFF"
      />
    </svg>
  )
};

export default RibbonIcon;