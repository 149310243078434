import React from 'react';

const RefreshIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M12 5C8.13401 5 5 8.13401 5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 7.02944 7.02944 3 12 3C14.3053 3 16.4084 3.8675 18 5.29178V4C18 3.44772 18.4477 3 19 3C19.5523 3 20 3.44772 20 4V8C20 8.55228 19.5523 9 19 9H15C14.4477 9 14 8.55228 14 8C14 7.44772 14.4477 7 15 7H16.899C15.6356 5.76172 13.907 5 12 5ZM20 11C20.5523 11 21 11.4477 21 12C21 16.9706 16.9706 21 12 21C9.6947 21 7.59157 20.1325 6 18.7082V20C6 20.5523 5.55228 21 5 21C4.44772 21 4 20.5523 4 20V16C4 15.4477 4.44772 15 5 15H9C9.55228 15 10 15.4477 10 16C10 16.5523 9.55228 17 9 17H7.10096C8.3644 18.2383 10.093 19 12 19C15.866 19 19 15.866 19 12C19 11.4477 19.4477 11 20 11Z"
        fill={props.svgColor}
      />
    </svg>
  )
};

export default RefreshIcon;