const RouteIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M8.77499 12.1182L15.8874 7.50428C16.0937 7.37004 16.2155 7.14133 16.2056 6.90267C16.1956 6.66153 16.0589 6.44525 15.8401 6.32593L8.07891 2.08983C7.85518 1.96801 7.58172 1.9705 7.35798 2.0948C7.13673 2.2191 7 2.44781 7 2.69392V11.9616C7 11.9641 7 11.9641 7 11.9666V21.2691C7 21.652 7.32318 21.9627 7.72093 21.9627C8.11869 21.9627 8.44187 21.652 8.44187 21.2691V13.9454L14.9178 21.739C15.0595 21.9105 15.2708 22 15.4821 22C15.6412 22 15.8004 21.9503 15.9321 21.8484C16.2429 21.6097 16.2951 21.1722 16.0465 20.8714L8.77499 12.1182ZM8.44435 3.8847L14.1149 6.97974L8.44435 10.659V3.8847Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default RouteIcon;