import React from 'react';

const ScrollIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M5.5 5C5.22386 5 5 5.22386 5 5.5V7H6V5.5C6 5.22386 5.77614 5 5.5 5ZM7.94999 5C7.98279 5.16155 8 5.32877 8 5.5V18.5C8 18.7606 8.19981 18.9751 8.45396 18.998L8.54523 18.998C8.80018 18.9751 9 18.7609 9 18.5V16C9 15.4477 9.44772 15 10 15H17V5.5C17 5.22386 16.7761 5 16.5 5H7.94999ZM19 15V5.5C19 4.11929 17.8807 3 16.5 3H5.5C4.11929 3 3 4.11929 3 5.5V8C3 8.55228 3.44772 9 4 9H6V18.5C6 19.8369 7.04885 20.9281 8.36922 20.9966C8.41261 20.9989 8.45622 21 8.5 21C8.53387 21 8.56758 20.9993 8.60113 20.998L18.4998 21C19.8805 21 21 19.8807 21 18.5V16C21 15.4477 20.5523 15 20 15H19ZM19 17H11V18.5C11 18.6707 10.9829 18.8374 10.9503 18.9985L18.5 19C18.776 18.9999 19 18.7761 19 18.5V17ZM9 7C9 6.44772 9.44772 6 10 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H10C9.44772 8 9 7.55228 9 7ZM9 10C9 9.44772 9.44772 9 10 9H12C12.5523 9 13 9.44772 13 10C13 10.5523 12.5523 11 12 11H10C9.44772 11 9 10.5523 9 10Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default ScrollIcon;