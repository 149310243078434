const SproutIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M12 9.97879C14.3305 9.87509 15.7327 9.39521 16.581 8.63171C17.3709 7.92079 17.8409 6.8143 17.9661 5.03212C16.0956 5.15244 14.6796 5.60588 13.7132 6.30874C12.6299 7.09654 12.0052 8.27319 12 9.97879ZM10.8231 6.6002C10.639 6.40334 10.4334 6.22351 10.2062 6.06127C9.07915 5.2562 7.57673 5 6 5C5.44772 5 5 5.44772 5 6C5 7.7837 5.23935 9.37945 6.2652 10.4908C7.13469 11.4327 8.40172 11.8527 10 11.9666V17.0432C9.28921 17.1083 8.61067 17.2514 7.95352 17.5042C6.99036 17.8746 6.1254 18.4604 5.29289 19.2929C4.90237 19.6834 4.90237 20.3166 5.29289 20.7071C5.68342 21.0976 6.31658 21.0976 6.70711 20.7071C7.3746 20.0396 8.00964 19.6254 8.67148 19.3708C9.33775 19.1146 10.0835 19 11 19C11.9165 19 12.6622 19.1146 13.3285 19.3708C13.9904 19.6254 14.6254 20.0396 15.2929 20.7071C15.6834 21.0976 16.3166 21.0976 16.7071 20.7071C17.0976 20.3166 17.0976 19.6834 16.7071 19.2929C15.8746 18.4604 15.0096 17.8746 14.0465 17.5042C13.3893 17.2514 12.7108 17.1083 12 17.0432V11.9807C14.5701 11.8772 16.5544 11.3464 17.919 10.1183C19.4866 8.70745 20 6.5978 20 4C20 3.44772 19.5523 3 19 3C16.39 3 14.1497 3.5183 12.5368 4.69126C11.8144 5.21664 11.2408 5.85683 10.8231 6.6002ZM9.99987 9.96051C9.99153 8.73266 9.59357 8.08145 9.04376 7.68873C8.58543 7.36135 7.92426 7.14287 7.03992 7.05027C7.12965 8.10753 7.36852 8.73741 7.7348 9.13422C8.10331 9.53343 8.75042 9.8525 9.99987 9.96051Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default SproutIcon;