const RouteIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M7.79289 3.29289C8.18342 2.90237 8.81658 2.90237 9.20711 3.29289L11.7071 5.79289C12.0976 6.18342 12.0976 6.81658 11.7071 7.20711L9.20711 9.70711C8.81658 10.0976 8.18342 10.0976 7.79289 9.70711C7.40237 9.31658 7.40237 8.68342 7.79289 8.29289L8.58579 7.5H6.75C5.7835 7.5 5 8.2835 5 9.25C5 10.2165 5.7835 11 6.75 11H17.25C19.3211 11 21 12.6789 21 14.75C21 16.8211 19.3211 18.5 17.25 18.5H9.85506C9.42479 19.9457 8.08551 21 6.5 21C4.567 21 3 19.433 3 17.5C3 15.567 4.567 14 6.5 14C8.08551 14 9.42479 15.0543 9.85506 16.5H17.25C18.2165 16.5 19 15.7165 19 14.75C19 13.7835 18.2165 13 17.25 13H6.75C4.67893 13 3 11.3211 3 9.25C3 7.17893 4.67893 5.5 6.75 5.5H8.58579L7.79289 4.70711C7.40237 4.31658 7.40237 3.68342 7.79289 3.29289ZM17.5 5C16.6716 5 16 5.67157 16 6.5C16 7.32843 16.6716 8 17.5 8C18.3284 8 19 7.32843 19 6.5C19 5.67157 18.3284 5 17.5 5ZM14 6.5C14 4.567 15.567 3 17.5 3C19.433 3 21 4.567 21 6.5C21 8.433 19.433 10 17.5 10C15.567 10 14 8.433 14 6.5ZM6.5 16C5.67157 16 5 16.6716 5 17.5C5 18.3284 5.67157 19 6.5 19C7.32843 19 8 18.3284 8 17.5C8 16.6716 7.32843 16 6.5 16Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default RouteIcon;