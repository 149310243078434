import React from 'react';

const DiscordIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M20.3303 5.52336C18.7535 4.80145 17.0888 4.2893 15.3789 4C15.1449 4.41829 14.9332 4.84865 14.7446 5.28928C12.9232 5.01482 11.071 5.01482 9.24961 5.28928C9.06095 4.84869 8.84924 4.41834 8.61535 4C6.90433 4.29174 5.2386 4.80511 3.66019 5.52713C0.526644 10.1633 -0.322811 14.6843 0.101917 19.1411C1.937 20.4969 3.99098 21.5281 6.17458 22.1897C6.66626 21.5284 7.10134 20.8268 7.47519 20.0925C6.76511 19.8273 6.07975 19.5001 5.42706 19.1146C5.59884 18.9901 5.76684 18.8617 5.92918 18.7371C7.82837 19.6303 9.90124 20.0933 12 20.0933C14.0987 20.0933 16.1715 19.6303 18.0707 18.7371C18.235 18.8711 18.403 18.9995 18.5729 19.1146C17.9189 19.5007 17.2323 19.8285 16.5209 20.0944C16.8943 20.8284 17.3294 21.5293 17.8216 22.1897C20.007 21.5307 22.0626 20.5001 23.898 19.143C24.3963 13.9745 23.0467 9.49503 20.3303 5.52336ZM8.01318 16.4002C6.82961 16.4002 5.85179 15.3261 5.85179 14.0047C5.85179 12.6833 6.79563 11.5998 8.0094 11.5998C9.22318 11.5998 10.1934 12.6833 10.1727 14.0047C10.1519 15.3261 9.21941 16.4002 8.01318 16.4002ZM15.9867 16.4002C14.8013 16.4002 13.8272 15.3261 13.8272 14.0047C13.8272 12.6833 14.7711 11.5998 15.9867 11.5998C17.2024 11.5998 18.1651 12.6833 18.1444 14.0047C18.1236 15.3261 17.193 16.4002 15.9867 16.4002Z"
        fill={props.svgColor}
      />
    </svg>
  )
};

export default DiscordIcon;