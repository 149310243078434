import React from 'react';

const TwitterIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M20.7071 4.70711C21.0976 4.31658 21.0976 3.68342 20.7071 3.29289C20.3165 2.90237 19.6834 2.90237 19.2928 3.29289L13.4135 9.17225L9.25513 3.41451C9.06712 3.1542 8.76555 3 8.44445 3H4C3.62447 3 3.2806 3.21039 3.10964 3.54475C2.93868 3.8791 2.96946 4.28106 3.18932 4.58549L9.40038 13.1854L3.29289 19.2929C2.90237 19.6834 2.90237 20.3166 3.29289 20.7071C3.68342 21.0976 4.31658 21.0976 4.70711 20.7071L10.5865 14.8277L14.7449 20.5855C14.9329 20.8458 15.2345 21 15.5556 21H20C20.3755 21 20.7194 20.7896 20.8904 20.4553C21.0613 20.1209 21.0306 19.7189 20.8107 19.4145L14.5996 10.8146L20.7071 4.70711ZM5.95576 5L16.0669 19H18.0442L7.93314 5H5.95576Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default TwitterIcon;