import React from 'react';

const SparklesIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M13 6.98047C13.5523 6.98047 14 7.42818 14 7.98047V8C14 11.3137 16.6863 14 20 14H20.0195C20.5718 14 21.0195 14.4477 21.0195 15C21.0195 15.5523 20.5718 16 20.0195 16H20C16.6863 16 14 18.6863 14 22V22.0234C14 22.5757 13.5523 23.0234 13 23.0234C12.4477 23.0234 12 22.5757 12 22.0234V22C12 18.6863 9.31371 16 6 16H5.98047C5.42818 16 4.98047 15.5523 4.98047 15C4.98047 14.4477 5.42818 14 5.98047 14H6C9.31371 14 12 11.3137 12 8V7.98047C12 7.42818 12.4477 6.98047 13 6.98047ZM13 11.876C12.2724 13.1872 11.1872 14.2724 9.87601 15C11.1872 15.7276 12.2724 16.8128 13 18.124C13.7276 16.8128 14.8128 15.7276 16.124 15C14.8128 14.2724 13.7276 13.1872 13 11.876Z"
        fill={props.svgColor}
      />
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M2 8.5C2 8.22386 2.22554 8.00437 2.49791 7.95887C3.75585 7.74875 4.74875 6.75585 4.95887 5.49791C5.00437 5.22554 5.22386 5 5.5 5C5.77614 5 5.99563 5.22554 6.04113 5.49791C6.25125 6.75585 7.24415 7.74875 8.50209 7.95887C8.77446 8.00437 9 8.22386 9 8.5C9 8.77614 8.77446 8.99563 8.50209 9.04113C7.24415 9.25125 6.25125 10.2442 6.04113 11.5021C5.99563 11.7745 5.77614 12 5.5 12C5.22386 12 5.00437 11.7745 4.95887 11.5021C4.74875 10.2442 3.75585 9.25125 2.49791 9.04113C2.22554 8.99563 2 8.77614 2 8.5Z"
        fill={props.svgColor}
      />
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M8 3.5C8 3.22386 8.22733 3.00641 8.49494 2.93829C9.20183 2.75833 9.75833 2.20183 9.93829 1.49494C10.0064 1.22733 10.2239 1 10.5 1C10.7761 1 10.9936 1.22733 11.0617 1.49494C11.2417 2.20183 11.7982 2.75833 12.5051 2.93829C12.7727 3.00641 13 3.22386 13 3.5C13 3.77614 12.7727 3.99359 12.5051 4.06171C11.7982 4.24167 11.2417 4.79817 11.0617 5.50506C10.9936 5.77267 10.7761 6 10.5 6C10.2239 6 10.0064 5.77267 9.93829 5.50506C9.75833 4.79817 9.20183 4.24167 8.49494 4.06171C8.22733 3.99359 8 3.77614 8 3.5Z"
        fill={props.svgColor}
      />
    </svg>
  )
};

export default SparklesIcon;